import { hasProtocol } from 'ufo';
import type { Creator } from '~/models/Creator';
import type { CreatorProfile } from '~/models/CreatorProfile';

export type UseAvatarOptions = {
	creator: MaybeRef<Creator | CreatorProfile | null | undefined>;
	fallbackUrl?: MaybeRef<string | undefined>;
};

export const useCreatorAvatar = (options: UseAvatarOptions) => {
	const supabase = useSupabaseClient();

	const url = computed(() => {
		const creator = unref(options.creator);

		if (!creator?.avatar) {
			return unref(options.fallbackUrl);
		}

		if (hasProtocol(creator.avatar)) {
			// We assume that the avatar is not uploaded to supabase if the avatar url has an protocol
			return creator.avatar;
		}

		return supabase.storage
			.from('avatars')
			.getPublicUrl(creator.avatar, {
				transform: {
					width: 200,
					height: 200,
					resize: 'cover',
				},
			}).data.publicUrl;
	});

	return url;
};
